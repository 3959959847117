body {
    font-family: 'Poppins', sans-serif;
}
.dropzone-input {

    width: 100% !important;
    height: 100% !important;
    position:   absolute ;
    top: 0;
    left: 0;
    opacity: 0;
    display: block !important;


    cursor: pointer;
}

body {
    overflow-y: hidden;
}